

import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase'; // Import your initialized auth and db from firebase
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { doc, getDocs, query, collection, where } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Profile = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [invoiceData, setInvoiceData] = useState([]);
    const [courses, setCourses] = useState([]); // State to hold courses
    const [userCourse, setUserCourse] = useState('');

    useEffect(() => {
        const fetchCourses = async () => {
            const user = auth.currentUser; // Get the current authenticated user
            if (user) {
                // console.log('Fetching courses for user with email:', user.email); // Debug log

                // Query the Users collection by email
                const usersQuery = query(
                    collection(db, "Users"),
                    where("email", "==", user.email)
                );

                try {
                    const querySnapshot = await getDocs(usersQuery);
                    if (!querySnapshot.empty) {
                        const userData = querySnapshot.docs[0].data(); // Get the first document data
                        // console.log('User data found:', userData); // Debug log
                        const paymentDetails = userData.paymentDetails || [];
                        // console.log('Payment details:', paymentDetails); // Debug log

                        // Set the user's course
                        setUserCourse(userData.course || 'Course not available');

                        // Filter out invalid payment details
                        const validCourses = paymentDetails.filter(course => 
                            course.invoiceId && course.invoiceUrl && course.orderId
                        );

                        setCourses(validCourses); // Set only valid courses
                    } else {
                        // console.error('No user data found for email:', user.email); // Debug log
                        // toast.error('No user data found.');
                    }
                } catch (error) {
                    // console.error('Error fetching user document:', error); // Debug log
                    toast.error('Error fetching user data. Please try again later.');
                }
            } else {
                // console.error('No authenticated user found.'); // Debug log
                toast.error('No authenticated user found.');
            }
        };

        fetchCourses();
    }, []);


   

    const handleChangePassword = async (e) => {
        e.preventDefault();
    
        if (newPassword !== confirmNewPassword) {
            toast.error('New passwords do not match');
            return;
        }
    
        const user = auth.currentUser;
        if (!user) {
            toast.error('No authenticated user found. Please log in again.');
            return;
        }

        // Re-authenticate the user
        const credential = EmailAuthProvider.credential(user.email, currentPassword);
        try {
            await reauthenticateWithCredential(user, credential);
            await updatePassword(user, newPassword);
            toast.success('Password changed successfully!');
            // Clear the input fields
            setCurrentPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
        } catch (error) {
            // console.error('Error changing password:', error); // Debug log
            toast.error('Error changing password. Please try again.');
        }
    };

    return (
        <div className="container mx-auto p-4 text-black">
            <h2 className="text-2xl font-bold mb-4 justify-center text-center">My Profile</h2>

            {/* <form onSubmit={handleChangePassword} className="mb-4">
                <div>
                  <h1 className='text-xl font-bold'>Password Resetting</h1>
                    <label htmlFor="currentPassword" className="block text-sm font-medium">Current Password</label>
                    <input
                        type="password"
                        id="currentPassword"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        className="mt-1 block w-full border rounded-md p-2"
                        required
                    />
                </div>
                <div className="mt-4">
                    <label htmlFor="newPassword" className="block text-sm font-medium">New Password</label>
                    <input
                        type="password"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="mt-1 block w-full border rounded-md p-2"
                        required
                    />
                </div>
                <div className="mt-4">
                    <label htmlFor="confirmNewPassword" className="block text-sm font-medium">Confirm New Password</label>
                    <input
                        type="password"
                        id="confirmNewPassword"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        className="mt-1 block w-full border rounded-md p-2"
                        required
                    />
                </div>
                <button type="submit" className="mt-4 bg-blue-500 text-white px-4 py-2 rounded">
                    Change Password
                </button>
            </form> */}

<form onSubmit={handleChangePassword} className="mb-6 md:w-1/3  w-full">
<h1 className='md:text-xl text-lg font-bold mb-4'>Change Password </h1>
                <div className="bg-gray-100 p-4 rounded-lg shadow-md md:ml-3">
                  
                    <label htmlFor="currentPassword" className="block text-sm font-medium">Current Password</label>
                    <input
                        type="password"
                        id="currentPassword"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        className="mt-1 block w-full border rounded-md p-2"
                        required
                    />
                    <div className="mt-4">
                        <label htmlFor="newPassword" className="block text-sm font-medium">New Password</label>
                        <input
                            type="password"
                            id="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="mt-1 block w-full border rounded-md p-2"
                            required
                        />
                    </div>
                    <div className="mt-4">
                        <label htmlFor="confirmNewPassword" className="block text-sm font-medium">Confirm New Password</label>
                        <input
                            type="password"
                            id="confirmNewPassword"
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            className="mt-1 block w-full border rounded-md p-2"
                            required
                        />
                    </div>
                    <button type="submit" className="mt-4 hover:bg-green-800 bg-green-600 text-black px-4 py-2 rounded">
                    Change Password
                </button>
                </div>
               
            </form>

            <h2 className="text-xl font-bold mb-4">Courses Enrolled</h2>
            {courses.length > 0 ? (
                <div className=" bg-green-100 border border-green-600 shadow-lg rounded-lg px-4 py-4 md:w-1/3 md:ml-3"> {/* Updated box shadow */}
                    {courses.map((course, index) => (
                        <div key={index} className="border-b last:border-b-0 text-black pb-2 ">
                            <h3 className="md:text-xl text-lg text-green-600 font-bold">{userCourse}</h3>
                            <p className="md:text-md text-sm text-black">Internship</p>
                            <button
                                onClick={() => window.open(course.invoiceUrl, '_blank')} // Changed to button
                                className="mt-4 hover:bg-green-800  bg-green-600 text-black px-4 py-2 rounded " // Button styling
                            >
                                Download Invoice
                            </button>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No courses found.</p>
            )}
            <ToastContainer />
        </div>
    );
};

export default Profile;


