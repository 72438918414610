import React, { useState, useEffect , useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { FaUserCircle } from 'react-icons/fa'; // Importing a user icon from react-icons
import { auth } from '../firebase'; // Import your initialized auth from firebase
import { onAuthStateChanged } from 'firebase/auth';

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [showDropdown, setShowDropdown] = useState(false); // State to track dropdown visibility
  const navigate = useNavigate(); // Initialize useNavigate
  const dropdownRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user); // Set isLoggedIn based on user presence
    });

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false); // Close dropdown if clicked outside
      }
    };

    
    document.addEventListener('mousedown', handleClickOutside); // Add event listener

    return () => {
      unsubscribe(); // Cleanup subscription on unmount
      document.removeEventListener('mousedown', handleClickOutside); // Cleanup event listener
    };
  }, []);

  

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const handleLoginLogout = () => {
    if (isLoggedIn) {
      // Logic for logging out
      auth.signOut() // Call Firebase sign-out
        .then(() => {
          setIsLoggedIn(false); // Update local state
          navigate('/'); // Redirect to home page or wherever you want after logout
        });
    } else {
      // Navigate to login page
      navigate('/login');
    }
    setShowDropdown(false);
  };

  return (
    <div className="bg-black text-white py-4 px-8 flex justify-between items-center">
      <Link to="/" className="text-white">
        <div className="flex items-center justify-center md:justify-start">
          <div className='h-6 w-8'>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/project-emblock.appspot.com/o/assests%2Femblocklogo3.png?alt=media&token=e2fd552b-7338-460d-ba8f-1634177267a7"
              alt="Logo"
              className="w-full h-full"
            />
          </div>
          <h2 className='text-xl font-semibold ml-1'>EMBLOCK LEARN</h2>
        </div>
      </Link>
      <div className="relative">
        <FaUserCircle className="h-8 w-8 cursor-pointer" onClick={toggleDropdown} />
        {showDropdown && (
          <div ref={dropdownRef} className="absolute md:right-10 right-2 md:bottom-[-42px] mt-2 md:w-48 w-28 bg-white text-black rounded-md shadow-lg z-50">
            <button
              onClick={handleLoginLogout}
              className="block px-4 py-2 text-sm text-black font-semibold hover:bg-green-800 w-full text-center bg-green-600"
            >
              {isLoggedIn ? 'Logout' : 'Login'} {/* Conditional rendering */}
            </button>
            <hr className="border-black " />
            {isLoggedIn && ( // Add My Profile button only if logged in
              <button
                onClick={() => navigate('/profile')} // Navigate to profile on click
                className="block px-4 py-2 text-sm text-black font-semibold hover:bg-green-800 w-full text-center bg-green-600"
              >
                My Profile
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
