import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import Auth
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCreflX62JemxL0z_njqDdHw1vh0eqG8TU",
  authDomain: "project-emblock.firebaseapp.com",
  projectId: "project-emblock",
  storageBucket: "project-emblock.appspot.com",
  messagingSenderId: "1087971879166",
  appId: "1:1087971879166:web:83b3251c5dc6aaf6331daf",
  measurementId: "G-6N7FYEPR5V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Initialize Auth
const db = getFirestore(app); // Initialize Firestore
const storage = getStorage(app); // Initialize Storage

export { auth,db, storage }; // Export Auth